import React from "react";
// import { useAuth } from "../utils/AuthContext";
// import Logo from "../static/img/logo.png";
// import User from "../static/img/user.png";
// import { NavLink } from "react-router-dom"; // Import NavLink

const Navigation = () => {
  // const { currentUser } = useAuth();

  return (
    <footer className="bg-dark mt-auto py-4">
      <div className="container px-5">
        <div className="row align-items-center justify-content-between flex-column flex-sm-row">
          <div className="col-auto">
            <div className="small m-0 text-white">
              Copyright &copy; Pledge Realm 2024
            </div>
          </div>
          <div className="col-auto">
            <a className="link-light small" href="#!">
              Privacy
            </a>
            <span className="mx-1 text-white">&middot;</span>
            <a className="link-light small" href="#!">
              Terms
            </a>
            <span className="mx-1 text-white">&middot;</span>
            <a className="link-light small" href="#!">
              Contact
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Navigation;
