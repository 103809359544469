import React, { useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FaCode } from "react-icons/fa";
import { unescapeHtml } from "../../utils/unescapeHtml";

function HtmlEditor({
  htmlContent,
  setHtmlContent,
  editMode,
  setContent,
  switchEditorMode,
}) {
  const quillRef = useRef(null);

  // Setup the toolbar configuration with all the options
  const [modules] = useState({
    toolbar: {
      container: "#rich-toolbar", // Linking to the custom toolbar ID
      handlers: {
        customButton: function () {
          switchEditorMode();
        },
      },
    },
  });

  const handleChange = (content, delta, source, editor) => {
    // editor.getHTML() is a method that gets the inner HTML
    let html = editor.getHTML();
    // html.replace("<p>", "");
    // html.replace("</p>", "");
    setHtmlContent(html); // Update the state with the HTML content
    setContent(unescapeHtml(html)); // Update the state with the HTML content
  };

  return (
    <div className="text-editor">
      <RichToolbar />
      <ReactQuill
        ref={quillRef}
        theme="snow"
        value={htmlContent}
        onChange={handleChange}
        modules={modules}
      />
    </div>
  );
}

// Custom Toolbar component including all toolbar options
const RichToolbar = () => (
  <div id="rich-toolbar">
    <button className="ql-customButton" title="Switch to Text Editor">
      <FaCode />
    </button>
  </div>
);

export default HtmlEditor;
