import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import { app } from "../../../utils/firebase";

function Logout() {
  const navigate = useNavigate();
  const auth = getAuth(app);

  useEffect(() => {
    signOut(auth)
      .then(() => {
        // Redirect to login or any other page after successful logout
        navigate("/");
      })
      .catch((error) => {
        // Handle any errors during logout
        console.error("Logout failed", error);
      });
  }, [navigate, auth]);

  // Optionally, render nothing or a loading indicator
  return null;
}

export default Logout;
