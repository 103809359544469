import React from "react";

function HomePage() {
  return (
    <div>
      <h1>Welcome to Pledge Realm</h1>
      <p>
        Embark on an innovative journey with us, where your ideas take flight in
        a community-driven crowdfunding arena.
      </p>
      <p>
        At Pledge Realm, we redefine the essence of crowdfunding by intertwining
        it with an immersive project management experience. What sets us apart
        is not just our platform, but the promise of a partnership that nurtures
        your project from concept to completion.
      </p>
      <p>
        Our unique approach empowers creators to not only launch their projects
        but to bring their audience along on the journey. Through regular
        updates and community engagement, we build a foundation of trust and
        collaboration, transforming followers into supporters.
      </p>
      <p>
        By fostering a culture of transparency and support, we enable creators
        to refine their offerings, gain invaluable insights, and, when the time
        is right, successfully introduce their products to the market.
      </p>
      <p>
        Pledge Realm is more than a platform; it's a community where creators
        find the resources, knowledge, and motivation to turn their visions into
        reality. Join us in shaping a world where creativity knows no bounds.
      </p>
    </div>
  );
}

export default HomePage;
