import React, { useEffect, useState } from "react";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";

function Dashboard() {
  const [projects, setProjects] = useState([]);
  const navigate = useNavigate();
  const db = getFirestore();
  const auth = getAuth();

  useEffect(() => {
    if (!auth.currentUser) {
      navigate("/login"); // Redirect to login if not authenticated
      return;
    }

    const fetchProjects = async () => {
      const q = query(
        collection(db, "projects"),
        where("userId", "==", auth.currentUser.uid)
      );
      const querySnapshot = await getDocs(q);
      const projectsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProjects(projectsData);
    };

    fetchProjects();
  }, [navigate, auth.currentUser, db]);

  return (
    <div className="container mt-3 mb-3">
      <div className="d-flex justify-content-between align-items-center">
        <h1>Welcome to the Dashboard</h1>
        <button
          className="btn btn-primary"
          onClick={() => navigate("/create-project")}
        >
          Add New Project
        </button>
      </div>
      <div className="row">
        {projects.map((project) => (
          <div className="col-md-4 pt-2 pb-2" key={project.id}>
            <div
              className="card"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(`/project/${project.id}`)}
            >
              <div className="card-body">
                <h5 className="card-title">{project.title}</h5>
                <p className="card-text">{project.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Dashboard;
