// import logo from "./logo.svg";
import "./App.css";

import { AuthProvider } from "./utils/AuthContext";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./views/public/HomePage";
// import Auth from "./views/public/Auth";
import Login from "./views/public/Auth/Login";
import Register from "./views/public/Auth/Register";
import Logout from "./views/public/Auth/Logout";
import PrivateRoute from "./views/middleware/PrivateRoute";
import Profile from "./views/private/Profile";
import Dashboard from "./views/private/Dashboard";
import Test from "./views/Test";
import Bootstrap from "./views/middleware/Bootstrap";
import About from "./views/public/About";
import CreateProject from "./views/private/CreateProject";
import ProjectDetail from "./views/private/ProjectDetail";
import ModifyProject from "./views/private/ModifyProject";

function App() {
  return (
    <Router>
      <AuthProvider>
        <Routes>
          {/* Public Routes Bootstrap */}
          <Route element={<Bootstrap />}>
            <Route path="/" element={<HomePage />} />
            <Route path="/about" element={<About />} />
            <Route path="/signup" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/test" element={<Test />} />

            {/* Private Routes */}
            <Route element={<PrivateRoute />}>
              <Route path="/profile" element={<Profile />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/create-project" element={<CreateProject />} />
              <Route path="/project/:projectId" element={<ProjectDetail />} />
              <Route
                path="/project/modify/:projectId"
                element={<ModifyProject />}
              />
              {/* Add this line */}
            </Route>
          </Route>
        </Routes>
      </AuthProvider>
    </Router>
  );
}

export default App;
