import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  doc,
  updateDoc,
  getDoc,
  deleteDoc,
  getFirestore,
} from "firebase/firestore";
import ProjectSettingsSidebar from "../../components/ProjectManagement/ProjectSettingsSidebar";
import QuillEditor from "../../components/ProjectManagement/QuillEditor";
import HtmlEditor from "../../components/ProjectManagement/HtmlEditor";
import { escapeHtml } from "../../utils/escapeHtml";

function ConfirmModal({ isOpen, onClose, onConfirm, projectName }) {
  const [inputValue, setInputValue] = useState("");
  const isInputCorrect = inputValue === projectName;

  if (!isOpen) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1050,
      }}
    >
      <div
        style={{
          background: "white",
          padding: "20px",
          borderRadius: "5px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          width: "90%",
          maxWidth: "500px",
          zIndex: 1051,
        }}
      >
        <h5>Confirm Deletion</h5>
        <p>
          Are you sure you want to delete this project? Please type the name of
          the project to confirm: {projectName}
        </p>
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          className="form-control mb-3"
          placeholder="Type project name to confirm"
        />
        <div className="d-flex justify-content-end">
          <button className="btn btn-secondary mr-2" onClick={onClose}>
            Cancel
          </button>
          <button
            className="btn btn-danger"
            onClick={onConfirm}
            disabled={!isInputCorrect}
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
}

function ModifyProject() {
  const { projectId } = useParams();

  const [showConfirm, setShowConfirm] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [content, setContent] = useState("");
  const [htmlContent, setHtmlContent] = useState("");
  const [editMode, setEditMode] = useState("rich-text");
  const [isPublic, setIsPublic] = useState(true);
  const navigate = useNavigate();
  const db = getFirestore();

  useEffect(() => {
    const fetchProject = async () => {
      const docRef = doc(db, "projects", projectId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setTitle(docSnap.data().title);
        setDescription(docSnap.data().description);
        setContent(docSnap.data().content || "");
        setHtmlContent(escapeHtml(docSnap.data().content) || "");
        setIsPublic(docSnap.data().isPublic || false);
      } else {
        alert("Project not found");
        navigate("/dashboard");
      }
    };

    fetchProject();
  }, [db, projectId, navigate]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    const projectRef = doc(db, "projects", projectId);
    try {
      await updateDoc(projectRef, {
        title,
        description,
        content,
        isPublic,
      });

      //   alert("Project updated successfully");
      navigate(`/project/${projectId}`);
    } catch (error) {
      alert("Error updating project: " + error.message);
    }
  };

  // Function to handle cancel action
  const handleCancel = () => {
    navigate(`/project/${projectId}`); // Navigate back without saving any changes
  };

  const handleDelete = async () => {
    try {
      await deleteDoc(doc(db, "projects", projectId));
      alert("Project deleted successfully");
      navigate("/dashboard");
    } catch (error) {
      alert("Error deleting project: " + error.message);
    }
  };

  const switchEditorMode = () => {
    if (editMode === "html") {
      setEditMode("rich-text");
    } else {
      setEditMode("html");
    }
  };

  return (
    <div className="container mt-3 mb-3">
      <div className="row">
        <div className="col-md-8">
          <h1>Modify Project</h1>
          <form onSubmit={handleUpdate}>
            <div className="mb-3">
              <label htmlFor="title" className="form-label">
                Title
              </label>
              <input
                type="text"
                className="form-control"
                id="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="description" className="form-label">
                Description
              </label>
              <input
                type="text"
                className="form-control"
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>

            <div className="mb-3">
              <label htmlFor="content" className="form-label">
                Content
              </label>

              {editMode === "rich-text" ? (
                <QuillEditor
                  editMode={editMode}
                  content={content}
                  setContent={setContent}
                  setHtmlContent={setHtmlContent}
                  switchEditorMode={switchEditorMode}
                />
              ) : (
                <HtmlEditor
                  editMode={editMode}
                  htmlContent={htmlContent}
                  setContent={setContent}
                  setHtmlContent={setHtmlContent}
                  switchEditorMode={switchEditorMode}
                />
              )}
            </div>

            <button type="submit" className="btn btn-primary">
              Update Project
            </button>
            <button
              type="button"
              className="btn btn-danger ml-2"
              onClick={() => setShowConfirm(true)}
            >
              Delete
            </button>
            <button
              type="button"
              className="btn btn-secondary ml-2"
              onClick={handleCancel}
            >
              Cancel
            </button>
          </form>
        </div>

        <ProjectSettingsSidebar
          isPublic={isPublic}
          togglePublic={() => setIsPublic(!isPublic)}
        />
      </div>
      <ConfirmModal
        isOpen={showConfirm}
        onClose={() => setShowConfirm(false)}
        onConfirm={handleDelete}
        projectName={title} // Assuming 'title' holds the name of the project
      />
    </div>
  );
}

export default ModifyProject;
