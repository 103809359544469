export const escapeHtml = (unsafe) => {
  return unsafe.replace(/[&<>"']/g, (m) => {
    switch (m) {
      case "&":
        return "&amp;";
      case "<":
        return "&lt;";
      case ">":
        return "&gt;";
      case "'":
        return "&#039;";
      case '"':
        return "&quot;";
      default:
        return "&#039;";
    }
  });
};
