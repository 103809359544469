export const unescapeHtml = (safe) => {
  return safe.replace(/&amp;|&lt;|&gt;|&quot;|&#039;/g, (m) => {
    switch (m) {
      case "&amp;":
        return "&";
      case "&lt;":
        return "<";
      case "&quot;":
        return '"';
      case "&#039;":
        return "'";
      case "&gt;":
        return ">";
      default:
        return "'";
    }
  });
};
