import React, { useState, useEffect } from "react";
import { signInWithEmailAndPassword, getAuth } from "firebase/auth";
import { app } from "../../../utils/firebase";
import { Link, useNavigate } from "react-router-dom";

function Login({ action }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const auth = getAuth(app);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        navigate("/"); // Update with your protected route
      }
    });

    return unsubscribe; // Cleanup subscription
  }, [auth, navigate]);

  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      // Navigation is handled by the onAuthStateChanged listener
    } catch (error) {
      console.error("Error signing in with password and email", error);
    }
  };

  return (
    <div className="vh-100 d-flex justify-content-center align-items-center">
      <div className="w-100" style={{ maxWidth: "400px" }}>
        <h2 className="mb-3 text-center">Login</h2>
        <div className="card">
          <div className="card-body">
            <form>
              <div className="mb-3">
                <label htmlFor="emailInput" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="emailInput"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter email"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="passwordInput" className="form-label">
                  Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="passwordInput"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                />
              </div>
              <div className="d-grid gap-2">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleLogin}
                >
                  Login
                </button>
              </div>
              <div className="text-center mt-3">
                <Link to="/reset">Forgot Password?</Link>
              </div>
            </form>
          </div>
        </div>
        <div className="text-center mt-3">
          Don't have an account?
          <br />
          <a className="btn btn-link" href="/signup">
            Create one here
          </a>
        </div>
      </div>
    </div>
  );
}

export default Login;
