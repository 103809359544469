import React, { useState } from "react";
import { createUserWithEmailAndPassword, getAuth } from "firebase/auth";
import { app } from "../../../utils/firebase";
import { useNavigate } from "react-router-dom";

function Register({ action }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const auth = getAuth(app);

  const handleCreateAccount = async () => {
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      // Navigate to a different route upon successful account creation
      navigate("/dashboard"); // Adjust the path as necessary
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="vh-100 d-flex justify-content-center align-items-center">
      <div className="w-100" style={{ maxWidth: "400px" }}>
        <h3 className="text-danger text-center">
          This is still under development
          <br /> Registration has been turned off
        </h3>
        <h2 className="mb-3 text-center">Create Account</h2>
        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}
        <div className="card">
          <div className="card-body">
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="mb-3">
                <label htmlFor="emailInput" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="emailInput"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Enter email"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="passwordInput" className="form-label">
                  Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="passwordInput"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Password"
                />
              </div>
              <div className="d-grid gap-2">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleCreateAccount}
                >
                  Create Account
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="text-center mt-3">
          Have an account?
          <br />
          <a className="btn btn-link" href="/login">
            Log in here
          </a>
        </div>
      </div>
    </div>
  );
}

export default Register;
