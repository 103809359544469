import React from "react";

const Test = () => {
  return (
    <div>
      <h1>Welcome to the Test</h1>
      <p>This is a boilerplate React page.</p>
    </div>
  );
};

export default Test;
