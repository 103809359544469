import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { doc, getDoc, getFirestore } from "firebase/firestore";

function ProjectDetail() {
  const { projectId } = useParams();
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [iframeHeight, setIframeHeight] = useState(""); // Default height

  const navigate = useNavigate();

  useEffect(() => {
    const db = getFirestore();
    const projectRef = doc(db, "projects", projectId);

    const fetchProject = async () => {
      try {
        const docSnap = await getDoc(projectRef);
        if (docSnap.exists()) {
          setProject(docSnap.data());
        } else {
          setError("No such project found!");
        }
      } catch (err) {
        setError("Error fetching project: " + err.message);
      }
      setLoading(false);
    };

    fetchProject();
  }, [projectId]);

  useEffect(() => {
    const handleResize = (event) => {
      console.log("Received message:", event.data);
      if (event.data && event.data.iframeHeight) {
        setIframeHeight(`${event.data.iframeHeight}px`);
      }
    };

    window.addEventListener("message", handleResize);

    return () => {
      window.removeEventListener("message", handleResize);
    };
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const iframeHtml = `
  <html>
  <head>
    <style>
    body { 
      margin: 0; 
      font-family: Arial, sans-serif;
    }
    body, html { 
      margin: 0; 
      padding: 0; 
      overflow: hidden; 
      height: 100%;
    }
    </style>
  </head>
  <body>
    ${project.content || ""}
    <script>
      window.onload = function() {
        const height = document.documentElement.scrollHeight;
        console.log('Content fully loaded, height:', height);

        window.parent.postMessage({ iframeHeight: document.body.scrollHeight }, '*');
      };
    </script>

  </body>
  </html>
`;

  return (
    <div className="container mt-3 mb-3">
      <div className="d-flex justify-content-between align-items-center">
        <h2>{project.title}</h2>
        <button
          className="btn btn-primary"
          onClick={() => navigate(`/project/modify/${projectId}`)}
        >
          Modify Project
        </button>
      </div>
      <p>{project.description}</p> {/* Display the description as plain text */}
      {/* iframe is used in case the user put reccursive JS in there, the page will not break other projects  */}
      <iframe
        title={`${project.title} Content`}
        srcDoc={iframeHtml}
        style={{ width: "100%", height: `${iframeHeight}`, border: "none" }}
        sandbox="allow-same-origin allow-scripts"
      />
    </div>
  );
}

export default ProjectDetail;
