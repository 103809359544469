import React from "react";

function ProjectSettingsSidebar({ isPublic, togglePublic }) {
  return (
    <div className="col-md-4">
      <h2>Settings</h2>
      <div className="mb-3">
        <label className="form-label">Project Visibility</label>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            id="projectVisibility"
            checked={isPublic}
            onChange={togglePublic}
          />
          <label className="form-check-label" htmlFor="projectVisibility">
            {isPublic ? "Public" : "Private"}
          </label>
        </div>
      </div>
    </div>
  );
}

export default ProjectSettingsSidebar;
