import React, { useState, useEffect } from "react";
import { getAuth, updateProfile } from "firebase/auth";

function Profile() {
  const auth = getAuth();
  const user = auth.currentUser;
  const [displayName, setDisplayName] = useState("");
  const [photoURL, setPhotoURL] = useState("");

  useEffect(() => {
    // Initialize form with current user profile data
    if (user) {
      setDisplayName(user.displayName || "");
      setPhotoURL(user.photoURL || "");
    }
  }, [user]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (user) {
      try {
        // Update user profile
        await updateProfile(auth.currentUser, {
          displayName: displayName,
          photoURL: photoURL,
        });
        alert("Profile updated successfully!");
      } catch (error) {
        alert("Failed to update profile: " + error.message);
      }
    }
  };

  return (
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="card shadow">
            <div className="card-header p-4">
              <h2 className="mb-0">Your Profile</h2>
            </div>
            <div className="card-body p-4">
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="displayName" className="form-label">
                    Display Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="displayName"
                    value={displayName}
                    onChange={(e) => setDisplayName(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor="photoURL" className="form-label">
                    Photo URL
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="photoURL"
                    value={photoURL}
                    onChange={(e) => setPhotoURL(e.target.value)}
                  />
                </div>
                <button type="submit" className="btn btn-primary">
                  Update Profile
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
