import React, { useState } from "react";
import { getFirestore, addDoc, collection } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ProjectSettingsSidebar from "../../components/ProjectManagement/ProjectSettingsSidebar";

function CreateProject() {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [content, setContent] = useState(""); // State for content field
  const [editMode, setEditMode] = useState("rich-text"); // Edit mode toggle
  const [isPublic, setIsPublic] = useState(true); // State for project visibility
  const db = getFirestore();
  const auth = getAuth();
  const navigate = useNavigate();

  const handleCreateProject = async (e) => {
    e.preventDefault();
    if (!title || !description || !content) {
      alert("Title, description, and content are required!");
      return;
    }
    try {
      await addDoc(collection(db, "projects"), {
        title,
        description,
        content, // Store the content from Quill editor or HTML
        userId: auth.currentUser.uid,
        isPublic, // Add the visibility status
      });

      navigate(`/dashboard`);
    } catch (error) {
      console.error("Error adding document: ", error);
      alert("Error creating project.");
    }
  };

  return (
    <div className="container mt-3 mb-3">
      <div className="row">
        <div className="col-md-8">
          <h1>Create Project</h1>
          <form onSubmit={handleCreateProject}>
            <div className="mb-3">
              <label htmlFor="title" className="form-label">
                Title
              </label>
              <input
                type="text"
                className="form-control"
                id="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="description" className="form-label">
                Description
              </label>
              <input
                type="text"
                className="form-control"
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="content" className="form-label">
                Content
              </label>
              {editMode === "rich-text" ? (
                <ReactQuill
                  theme="snow"
                  value={content}
                  onChange={setContent}
                />
              ) : (
                <textarea
                  className="form-control"
                  id="content"
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                  rows="10"
                />
              )}
            </div>
            <button type="submit" className="btn btn-primary">
              Create Project
            </button>
          </form>
        </div>
        <ProjectSettingsSidebar
          isPublic={isPublic}
          togglePublic={() => setIsPublic(!isPublic)}
          switchEditorMode={() =>
            setEditMode(editMode === "rich-text" ? "html" : "rich-text")
          }
          currentMode={editMode}
        />
      </div>
    </div>
  );
}

export default CreateProject;
