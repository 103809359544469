import React from "react";
import { useAuth } from "../utils/AuthContext";
import Logo from "../static/img/logo.png";
// import User from "../static/img/user.png";
import { NavLink } from "react-router-dom"; // Import NavLink

const Navigation = () => {
  const { currentUser } = useAuth();

  return (
    <>
      <style>
        {`
@media (min-width: 992px) {
  .navbar .nav-item.dropdown:hover .dropdown-menu {
    display: block !important;
    right: 0;
    left: auto;
    margin-top: 0px;
  }
}
        `}
      </style>

      <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        <div className="container px-5">
          <NavLink className="navbar-brand" to="/">
            <img
              className="image"
              width="52px"
              height="100%"
              style={{
                maxHeight: "52px",
                filter: "drop-shadow(0px 0px 2px #fff)",
              }}
              src={Logo}
              alt="Pledge Realm Logo"
            />
            Pledge Realm
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="navbar-collapse collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mb-lg-0 mb-2 ms-auto">
              {currentUser ? (
                <ul className="navbar-nav ms-auto">
                  {" "}
                  {/* Adjusted for alignment */}
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/dashboard">
                      Dashboard
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/profile">
                      Profile
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/logout">
                      Logout
                    </NavLink>
                  </li>
                </ul>
              ) : (
                <>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      to="/about"
                      activeclassname="active"
                    >
                      Start a Project
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      className="nav-link"
                      to="/about"
                      activeclassname="active"
                    >
                      About
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/login">
                      Login
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/signup">
                      Sign Up
                    </a>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navigation;
