import React, { useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { FaCode } from "react-icons/fa";
import { escapeHtml } from "../../utils/escapeHtml";

function QuillEditor({
  content,
  setContent,
  editMode,
  switchEditorMode,
  setHtmlContent,
}) {
  const quillRef = useRef(null);

  // Setup the toolbar configuration with all the options
  const [modules] = useState({
    toolbar: {
      container: "#rich-toolbar", // Linking to the custom toolbar ID
      handlers: {
        customButton: function () {
          switchEditorMode();
        },
      },
    },
  });

  const handleChange = (content, delta, source, editor) => {
    // editor.getHTML() is a method that gets the inner HTML
    const html = editor.getHTML();
    setHtmlContent(escapeHtml(html)); // Update the state with the HTML content
    setContent(content); // Update the state with the Quill content
  };

  return (
    <div className="text-editor">
      <RichToolbar />
      <ReactQuill
        ref={quillRef}
        theme="snow"
        value={content}
        onChange={handleChange}
        modules={modules}
      />
    </div>
  );
}

// Custom Toolbar component including all toolbar options
const RichToolbar = () => (
  <div id="rich-toolbar">
    <button className="ql-customButton" title="Swtich to Code Editor">
      <FaCode />
    </button>
    <select className="ql-header">
      <option value="1">Heading 1</option>
      <option value="2">Heading 2</option>
      <option defaultValue></option>
    </select>
    <button className="ql-bold" title="Bold"></button>
    <button className="ql-italic" title="Italic"></button>
    <button className="ql-underline" title="Underline"></button>
    <select className="ql-color">
      <option value="red"></option>
      <option value="green"></option>
      <option value="blue"></option>
      <option value="orange"></option>
      <option value="violet"></option>
      <option value="#d0d1d2"></option>
      <option defaultValue></option>
    </select>
    <button className="ql-list" value="ordered" title="Ordered List"></button>
    <button className="ql-list" value="bullet" title="Bullet List"></button>
    <button className="ql-link" title="Insert Link"></button>
    <button className="ql-clean" title="Remove Formatting"></button>
  </div>
);

export default QuillEditor;
